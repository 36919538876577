angular.module('licenseFilter', [])
	.constant("licenseFilterTypes", [
	                                 
        // MFG + ADMIN
		{id: "orgId", 		label: "Organization ID", 	type: "STRING",		allow: ["admin", "mfg"]},

   		{id: "customerName", label: "Customer Name", 	type: "STRING", 	allow: ["admin", "mfg"]},

   		{id: "licenseTerm",	label: "License Term",		type: "STRING", 	allow: ["admin", "mfg"]},
		{id: "licenseKey", 	label: "License Key",		type: "STRING", 	allow: ["admin", "mfg"]},

		{id: "createdBy", 	label: "Created By",		type: "STRING", 	allow: ["admin", "mfg"]},
		{id: "createdOn", 	label: "Date Created",		type: "DATE", 		allow: ["admin", "mfg"]},
		
		{id: "exported", 	label: "Exported",			type: "BOOL", 		allow: ["admin", "mfg"]},
		{id: "exportedBy", 	label: "Exported By",		type: "STRING", 	allow: ["admin", "mfg"]},
		{id: "exportedOn", 	label: "Date Exported",		type: "DATE", 		allow: ["admin", "mfg"]},
		{id: "maxResults", 	label: "Max Results",		type: "NUM", 		allow: ["admin", "mfg"]},

		// ADMIN only
   		//{id: "orgId", 		label: "Organization ID", 	type: "STRING",		allow: ["admin"]},
		{id: "orgAdmin", 	label: "Org Admin",			type: "STRING", 	allow: ["admin"]},

		{id: "active", 		label: "Active",			type: "BOOL", 		allow: ["admin"]},
		{id: "assigned", 	label: "Assigned",			type: "BOOL", 		allow: ["admin"]},

		{id: "expired", 	label: "Expired",			type: "BOOL", 		allow: ["admin"]},
		{id: "expiresOn", 	label: "Expiration Date",	type: "DATE", 		allow: ["admin"]}
	]);
